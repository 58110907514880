define("discourse/plugins/discourse-translator/discourse/components/translated-post", ["exports", "discourse-common/utils/decorators"], function (_exports, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend((_dec = (0, _decorators.default)("post.translated_text"), (_obj = {
    loading: function loading(translated_text) {
      return translated_text === true ? true : false;
    }
  }, (_applyDecoratedDescriptor(_obj, "loading", [_dec], Object.getOwnPropertyDescriptor(_obj, "loading"), _obj)), _obj)));

  _exports.default = _default;
});
define("discourse/plugins/discourse-translator/discourse/initializers/extend-for-translate-button", ["exports", "I18n", "discourse/lib/ajax-error", "discourse/lib/plugin-api", "discourse/lib/ajax"], function (_exports, _I18n, _ajaxError, _pluginApi, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function translatePost(post) {
    return (0, _ajax.ajax)("/translator/translate", {
      type: "POST",
      data: {
        post_id: post.get("id")
      }
    }).then(function (res) {
      post.setProperties({
        translated_text: res.translation,
        detected_lang: res.detected_lang
      });
    });
  }

  function initializeTranslation(api) {
    var siteSettings = api.container.lookup("site-settings:main");
    var currentUser = api.getCurrentUser();

    if (!currentUser) {
      return;
    }

    if (!siteSettings.translator_enabled) {
      return;
    }

    api.includePostAttributes("can_translate", "translated_text", "detected_lang");
    api.decorateWidget("post-menu:before", function (dec) {
      if (!dec.state.isTranslated) {
        return;
      }

      if (dec.state.isTranslating) {
        return dec.h("div.spinner.small");
      }

      var language = dec.attrs.detected_lang;
      var translator = siteSettings.translator;
      return dec.h("div.post-translation", [dec.h("hr"), dec.h("div.post-attribution", _I18n.default.t("translator.translated_from", {
        language: language,
        translator: translator
      })), dec.cooked(dec.attrs.translated_text)]);
    });
    api.attachWidgetAction("post-menu", "translate", function () {
      var state = this.state;
      state.isTranslated = true;
      state.isTranslating = true;
      this.scheduleRerender();
      var post = this.findAncestorModel();

      if (post) {
        return translatePost(post).catch(function (error) {
          (0, _ajaxError.popupAjaxError)(error);
          state.isTranslating = false;
          state.isTranslated = false;
        }).finally(function () {
          return state.isTranslating = false;
        });
      }
    });
    api.attachWidgetAction("post-menu", "hideTranslation", function () {
      this.state.isTranslated = false;
      var post = this.findAncestorModel();

      if (post) {
        post.set("translated_text", "");
      }
    });
    api.addPostMenuButton("translate", function (attrs, state) {
      if (!attrs.can_translate) {
        return;
      }

      var _ref = !state.isTranslated ? ["translate", "translator.view_translation"] : ["hideTranslation", "translator.hide_translation"],
          _ref2 = _slicedToArray(_ref, 2),
          action = _ref2[0],
          title = _ref2[1];

      return {
        action: action,
        title: title,
        icon: "globe",
        position: "first",
        className: state.isTranslated ? "translated" : null
      };
    });
  }

  var _default = {
    name: "extend-for-translate-button",
    initialize: function initialize() {
      (0, _pluginApi.withPluginApi)("0.1", function (api) {
        return initializeTranslation(api);
      });
    }
  };
  _exports.default = _default;
});
Ember.TEMPLATES["javascripts/components/translated-post"] = Ember.HTMLBars.template({"id":null,"block":"{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"post\",\"translated_text\"]]],null,{\"statements\":[[4,\"conditional-loading-spinner\",null,[[\"condition\",\"size\"],[[24,[\"loading\"]],\"small\"]],{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"post-translation\"],[8],[0,\"\\n      \"],[7,\"hr\",true],[8],[9],[0,\"\\n\\n      \"],[7,\"div\",true],[10,\"class\",\"post-attribution\"],[8],[0,\"\\n        \"],[1,[28,\"i18n\",[\"translator.translated_from\"],[[\"language\",\"translator\"],[[24,[\"post\",\"detected_lang\"]],[24,[\"siteSettings\",\"translator\"]]]]],false],[0,\"\\n      \"],[9],[0,\"\\n\\n      \"],[1,[28,\"html-safe\",[[24,[\"post\",\"translated_text\"]]],null],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}","meta":{"moduleName":"javascripts/discourse/templates/components/translated-post"}});
Ember.TEMPLATES["javascripts/connectors/post-after-cooked/translate"] = Ember.HTMLBars.template({"id":null,"block":"{\"symbols\":[],\"statements\":[[1,[28,\"translated-post\",null,[[\"post\"],[[23,0,[]]]]],false],[0,\"\\n\"]],\"hasEval\":false}","meta":{"moduleName":"javascripts/discourse/templates/connectors/post-after-cooked/translate"}});

